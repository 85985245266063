import React from 'react';
import About from './components/About/About.js';
import Brands from './components/Brands/Brands.js';
import Header from './components/Header/Header.jsx';
import Hero from './components/Hero/Hero.js';
import Portfolio from './components/Portfolio/Portfolio.js';
import Skills from './components/Skills/Skills.js';

// import components
import Services from './components/Services/Services';
import Testimonials from './components/Testimonials/Testimonials.js';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer.js';
import BackToTop from './components/BackToTop/BackToTop.js';
const App = () => {
  return <div>
    <Header/>
    <Hero/>
    <Brands/>
    <About/>
    <Skills/>
    <Portfolio/>
    <Services/>
    <Testimonials/>
    <Contact/>
    <Footer/>
    <BackToTop/>
  </div>;
};

export default App;
